



















































import { Action, Getter } from "vuex-class";
import { Component, Vue, Ref, Watch } from "vue-property-decorator";
import { FormBase, FormInput, FormSingleImageInput, FormError, FormSubmit } from "@/components/forms";
import GeneralArena from "@/components/general/arena.vue";

@Component({
  components: {
    FormBase,
    FormInput,
    FormSingleImageInput,
    FormError,
    FormSubmit,
    GeneralArena,
  },
})
export default class PageWorkspace extends Vue {
  @Getter("workspace/viewing") workspace!: Workspace;

  @Action("workspace/update") updateWorkspace!: ({ payload, id }: { payload: WorkspacePayload; id: number }) => Promise<void>;

  @Ref() form!: FormClass;

  errorResponse: ErrorResponse = {
    status: 0,
    errors: [],
  };

  payload: WorkspacePayload = {
    title: "",
    url: "",
    media: undefined,
    arena_level_1_title: "",
    arena_level_1_hex: "",
    arena_level_2_title: "",
    arena_level_2_hex: "",
    arena_level_3_title: "",
    arena_level_3_hex: "",
  };

  private submit() {
    this.updateWorkspace({ payload: this.payload, id: this.workspace.id })
      .catch((errorResponse: ErrorResponse) => (this.errorResponse = errorResponse))
      .finally(() => this.form.reset());
  }

  @Watch("workspace", { immediate: true })
  handleAccountChanged(workspace: Workspace) {
    this.payload = { ...workspace };
  }
}
